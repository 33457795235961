/* primary colors */
$umanity-blue: #41c2e7;
$umanity-orange: #f79824;

/* background blues */
$background-gray:	#F4F5F9;
$background-light: #335c81;
$background-medium: #274060;
$background-dark: #1B2845;
$faded-background-dark: rgba(27, 40, 69, 0.87);
$opac-faded-background-dark: rgb(118, 139, 185);

/* other colors */
$umanity-light-blue: #b0eeff;
$umanity-dark-blue: #00a0cd;
$umanity-darker-blue: #005269;
$umanity-light-orange: rgb(251, 221, 184);
$umanity-dark-orange: #9b6019;
$umanity-light-gray: #c9c9c9;
$umanity-medium-gray: #cac9c9;
$umanity-gray: rgb(52, 52, 52);
$umanity-white: #fff;
$umanity-black: #000;
$umanity-dark-red: rgb(146, 14, 14);
$umanity-red: rgb(209, 97, 97);
$umanity-light-red: rgb(248, 153, 153);
$umanity-dark-green: rgb(48, 139, 48);
$umanity-green: rgb(124, 192, 124);
$umanity-light-green: rgb(180, 204, 180);
$umanity-light-light-green: rgb(232, 255, 232);
$umanity-linear: linear-gradient(to right, $umanity-light-blue, $umanity-light-orange);
$umanity-white-faded: rgba(255, 255, 255, 0.411);
$umanity-blue-faded: rgba(65, 195, 231, 0.082);
$umanity-gray-text: rgb(75, 75, 75);
$umanity-teal: rgb(67, 134, 112);
$umanity-purple: rgb(127, 80, 165);
$umanity-yellow: #f7d024;
$umanity-blue-gray: #cee1ef;

$umanity-blue-linear: linear-gradient(to right, $umanity-darker-blue, $umanity-blue);
$umanity-orange-linear: linear-gradient(to right, $umanity-dark-orange, $umanity-orange);
$umanity-background-linear: linear-gradient(180deg, $umanity-darker-blue, $umanity-orange);

$umanity-background-blue: #10182b;


$box-shadow-low: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$box-shadow-medium: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
$box-shadow-high: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
$box-shadow-modal: 0 10px 20px 80vh rgba(0, 0, 0, 0.514), 0 6px 6px 80vh rgba(0, 0, 0, 0.39);
$box-shadow-std: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
$box-shadow-light-bottom: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;

$box-shadow-blue: 0 0 5px 2px $umanity-blue;
$box-shadow-orange: 0 0 5px 2px $umanity-orange;

.clickable:hover {
  cursor: pointer;
}

.content-container {
  padding: 10px;
  overflow: auto;
}

.clickable-text {
  cursor: pointer;
}
.clickable-text:hover {
  color: $umanity-light-blue;
}
.clickable-text:active {
  color: $umanity-light-orange;
}

.top-text {
  margin: 0 auto 10px;
  font-size: 2em;
  background: $umanity-blue-linear;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* DESKTOP */
@media screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}
/* MOBILE */
@media screen and (max-width: 767px) {
  .desktop-only {
    display: none;
  }
}

$card-font: "micr";
.card-cont {
  font: "micr";
}